.pageKeyContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.keyPageItem {
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    color: #333;
    padding: 10px;
    margin-bottom: 7px;
    cursor: pointer;
    transition: all 0.3s;
}

.keyPageItem:hover {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.homeBack {
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    border-radius: 7px 7px 7px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    cursor: pointer;
    margin-left: 15px;
    position: relative;
}

.homeIcon {
    color: white;
    margin-bottom: 4px;
    margin-left: 4px;
    font-size: 27px;
}

.clickAble {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
    background-color: transparent;
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    .pageKeyContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        height: 40px;
    }
    .keyPageItem {
        background: rgba(255, 255, 255, 0.53);
        border-radius: 16px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
        color: #333;
        padding: 10px;
        margin-bottom: 7px;
        cursor: pointer;
        transition: all 0.3s;
    }
    .homeBack {
        width: 30px;
        min-width: 30px;
        min-height: 30px;
        height: 30px;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #333333;
        box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
        cursor: pointer;
        margin-left: 5px;
        position: relative;
    }
    .homeIcon {
        color: white;
        margin-bottom: 0px;
        margin-left: 0px;
        font-size: 15px;
    }
}