.Container1 {
    width: 100vw;
    height: 100%;
    max-height: 100%;
    background-color: #f8fefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
    height: 120px;
    max-width: 1440px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.header .right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.header .center {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.contentContainer {
    width: 100%;
    max-width: 1440px;
    height: calc(100% - 120px);
    min-height: 700px;
    padding-bottom: 70px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
}

.box1content {
    width: 50%;
    height: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.madeAzmounBtn {
    width: 200px;
    height: 70px;
    background-color: #333333;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px 20px 20px 0px;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: large;
    transition: all 0.3s;
    margin-bottom: 50px;
    cursor: pointer;
}

.buyAccountBtn {
    width: 200px;
    height: 70px;
    background-color: #333333;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px 20px 20px 0px;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: large;
    transition: all 0.3s;
    cursor: pointer;
}

.box2content {
    width: 50%;
    height: 100%;
    padding: 50px;
    padding: 0px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box2content img {
    width: 80%;
    margin-bottom: 60px;
}


/* ------------- */

@media screen and (max-width: 690px) and (min-width: 320px) {
    .header {
        width: 100%;
        max-width: 1440px;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
    }
    .header .right {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .header .center {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }
    .contentContainer {
        width: 100%;
        max-width: 1440px;
        height: 100%;
        min-height: 700px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
        background-color: transparent;
        padding-top: 20px;
    }
    .box1content {
        width: 100%;
        height: 50%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .madeAzmounBtn {
        width: 200px;
        height: 70px;
        background-color: #333333;
        color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 20px 20px 20px 0px;
        box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
        font-family: "vazir", sans-serif;
        font-weight: 700;
        font-size: large;
        transition: all 0.3s;
        margin-bottom: 50px;
        cursor: pointer;
    }
    .buyAccountBtn {
        width: 200px;
        height: 70px;
        background-color: #333333;
        color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 20px 20px 20px 0px;
        box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
        font-family: "vazir", sans-serif;
        font-weight: 700;
        font-size: large;
        transition: all 0.3s;
        cursor: pointer;
    }
    .box2content {
        width: 100%;
        height: 250px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box2content img {
        width: 70%;
        margin-bottom: 20px;
    }
}