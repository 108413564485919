.formContainer {
    width: 22vw;
    max-width: 400px;
    min-width: 310px;
    height: 30vw;
    min-height: 450px;
    max-height: 490px;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.backContainer {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 30px;
    border-radius: 7px 7px 7px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
}

.back {
    cursor: pointer;
    color: white;
    font-size: 20px;
    margin-left: 5px;
    margin-bottom: 2px;
}

.formContainer h1 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: 45px;
    color: #333;
    text-align: center;
}

.formContainer h2 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    color: #333;
    text-align: right;
}

.formContainer h3 {
    width: 100%;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: medium;
    color: #333;
    padding-bottom: 10px;
}

.formContainer .form {
    width: 100%;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formContainer .form input {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 0px 10px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #333;
    text-align: center;
    caret-color: #333;
}

.formContainer .form input::placeholder {
    color: #333;
}

.formContainer .form input:focus {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 20px;
    padding: 0px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #333;
}

.formContainer button {
    width: 87%;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: large;
    font-weight: 900;
    background-color: #333;
    color: white;
    padding: 0;
}

.rulesContainer {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.rulesContainer p {
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    white-space: nowrap;
    color: #333;
}

.rulesContainer .rulesLink {
    margin: 0 4px;
    font-weight: 600;
    color: #f9a21c;
    cursor: pointer;
}

.usePassword {
    width: 100%;
    padding: 10px 20px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #f9a21c;
    cursor: pointer;
}

.usePassword p {
    margin-left: 4px;
}

.timerContainer {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #333;
}

.timerContainer .timer {
    margin-left: 4px;
}

.timerContainer .ResendCode {
    cursor: pointer;
}

@-moz-document url-prefix() {
    .formContainer {
        width: 350px;
        height: 400px;
        min-width: 300px;
        min-height: 400px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    .formContainer h1 {
        width: 100%;
        padding: 20px;
        font-family: "vazir", sans-serif;
        font-weight: 900;
        font-size: 35px;
        color: #333;
        margin-bottom: 20px;
        text-align: center;
    }
}