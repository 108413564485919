* {
    box-sizing: border-box !important;
}

.brTop {
    border-top: 0.8mm solid black;
}

.brLeft {
    border-left: 0.8mm solid black;
}

.brRight {
    border-right: 0.8mm solid black;
}

.brBottom {
    border-bottom: 0.8mm solid black;
}

.p2mm {
    padding: 2mm;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    height: 51mm;
    min-height: 51mm;
    max-height: 51mm;
}

.header .besmA {
    width: 100%;
    height: 5mm;
    min-height: 5mm;
    max-height: 5mm;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: x-small;
}

.header .testDetails {
    width: 100%;
    height: 7mm;
    min-height: 7mm;
    max-height: 7mm;
    border-top: 0.8mm solid black;
    border-left: 0.8mm solid black;
    border-right: 0.8mm solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .testDetails .testDetailsItem {
    width: 33.33%;
    padding: 2mm;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .testDetails .testDetailsItem .Label {
    font-size: x-small;
    font-weight: 300;
    text-align: center;
    margin-left: 3px;
}

.header .testDetails .testDetailsItem .text {
    font-size: x-small;
    font-weight: 800;
    text-align: center;
    /* white-space: nowrap; */
}

.leftBorder {
    border-left: 0.8mm solid black;
}

.header .testTimes {
    width: 100%;
    height: 7mm;
    min-height: 7mm;
    max-height: 7mm;
    border-top: 0.8mm solid black;
    border-left: 0.8mm solid black;
    border-right: 0.8mm solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .testTimes .testTimesItem {
    width: 25%;
    padding: 2mm;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .testTimes .testTimesItem .Label {
    font-size: x-small;
    font-weight: 300;
    text-align: center;
    margin-left: 3px;
}

.header .testTimes .testTimesItem .text {
    font-size: x-small;
    font-weight: 800;
    text-align: center;
    /* white-space: nowrap; */
}

.header .studenDetails {
    width: 100%;
    height: 11mm;
    min-height: 11mm;
    max-height: 11mm;
    border-top: 0.8mm solid black;
    border-left: 0.8mm solid black;
    border-right: 0.8mm solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .studenDetails .studenDetailsItem {
    width: 33.33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.studenDetailsItemOne {
    border-left: 0.8mm solid black;
}

.studenDetailsItemTwo {
    width: calc(100% - 33.33%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
}

.header .studenDetails .studenDetailsItemTwo .Label,
.header .studenDetails .studenDetailsItem .Label {
    font-size: x-small;
    font-weight: 300;
    text-align: center;
    margin-left: 3px;
}

.header .studenDetails .studenDetailsItemTwo .text,
.header .studenDetails .studenDetailsItem .text {
    font-size: x-small;
    font-weight: 800;
    text-align: center;
    /* white-space: nowrap; */
}

.header .pejvokLabel {
    width: 100%;
    height: 7mm;
    min-height: 7mm;
    max-height: 7mm;
    border: 0.8mm solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-small;
    font-weight: 600;
    text-align: center;
    padding: 2mm;
}

.header .azmounLabel {
    height: 9mm;
    min-height: 9mm;
    max-height: 9mm;
    width: 100%;
    border: 0.8mm solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: x-small;
    font-weight: 600;
    text-align: center;
    margin-top: 2mm;
    margin-bottom: 4mm;
}

.header .azmounLabel span:nth-child(1) {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3mm;
    font-size: x-small;
    font-weight: 400;
}

.header .azmounLabel span:nth-child(2) {
    width: calc(100% - 80px);
    height: 9mm;
    min-height: 9mm;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3mm;
    font-size: x-small;
    font-weight: 400;
    border-left: 0.4mm solid black;
    border-right: 0.4mm solid black;
}

.header .azmounLabel span:nth-child(3) {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3mm;
    font-size: x-small;
    font-weight: 400;
}


/* ------------- */

.headerTwo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    height: 51mm;
    min-height: 51mm;
    max-height: 51mm;
}

.radifOne {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    height: 30mm;
    min-height: 30mm;
    max-height: 30mm;
}

.radifTwo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-size: small;
    height: 5mm;
    min-height: 5mm;
    max-height: 5mm;
}

.radifThree {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-size: x-small;
    font-weight: 900;
    height: 13mm;
    min-height: 13mm;
    max-height: 13mm;
    border: 0.4mm solid black;
}

.tp1 {
    width: 10%;
    height: 100%;
    border-left: 0.4mm solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tp2 {
    width: 10%;
    height: 100%;
    border-left: 0.4mm solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tp3 {
    width: 25%;
    height: 100%;
    border-left: 0.4mm solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tp4 {
    width: 20%;
    height: 100%;
    border-left: 0.4mm solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tp5 {
    width: 10%;
    height: 100%;
    border-left: 0.4mm solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(131, 131, 131);
    font-weight: 900;
    font-size: small;
}

.tp6 {
    width: 10%;
    height: 100%;
    border-left: 0.4mm solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tp7 {
    width: 10%;
    height: 100%;
    border-left: 0.4mm solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tp8 {
    width: 20%;
    height: 100%;
    border-left: 0.4mm solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tp9 {
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(131, 131, 131);
    font-weight: 900;
    font-size: small;
}

.tpChild {
    width: 100%;
    height: 50%;
    border-bottom: 0.4mm solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tpChildTwo {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerTwo .rightSec {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-left: 0.8mm solid black;
}

.headerTwo .leftSec {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.headerTwo .centerSec {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-left: 0.8mm solid black;
}

.headerTwo .labelll {
    font-size: x-small;
    font-weight: 800;
    text-align: center;
    margin: 2mm;
    margin-left: 3px;
}

.headerTwo .texttt {
    font-size: x-small;
    font-weight: 300;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


/* rightSec */

.headerTwo .rightSec .p1 {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.headerTwo .rightSec .p1 .p1c1 {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.headerTwo .rightSec .p1 .p1c2 {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.headerTwo .rightSec .p1 .p1c3 {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


/* centerSec */

.headerTwo .centerSec span {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerTwo .centerSec span:nth-child(1) {
    width: 100%;
    text-align: center;
    font-size: small;
    font-weight: 900;
}

.headerTwo .centerSec span:nth-child(2) {
    width: 100%;
    text-align: center;
    font-size: x-small;
    font-weight: 900;
}

.headerTwo .centerSec span:nth-child(3) {
    width: 100%;
    text-align: center;
    font-size: xx-small;
    font-weight: 900;
}

.headerTwo .centerSec span:nth-child(4) {
    width: 100%;
    text-align: center;
    font-size: xx-small;
    font-weight: 900;
}

.headerTwo .centerSec span:nth-child(5) {
    width: 100%;
    text-align: center;
    font-size: x-small;
    font-weight: 900;
}


/* leftSec */

.headerTwo .leftSec .p2 {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.headerTwo .leftSec .p2p1 {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.headerTwo .leftSec .p2p2 {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(131, 131, 131);
    font-weight: 900;
    font-size: small;
    text-align: center;
    line-height: 0.6cm;
}

.headerTwo .leftSec .p2p1 .p2p1c1 {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.headerTwo .leftSec .p3 {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.headerTwo .leftSec .p3 .p3c1 {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


/* ------------- */


/* ------------- */


/* ------------- */

.headerThree {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    height: 51mm;
    min-height: 51mm;
    max-height: 51mm;
}

.thHCon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    height: 40mm;
    min-height: 40mm;
    max-height: 40mm;
    border: 0.8mm solid #333;
}

.thHCon .part {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.thHCon .partcenter {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2mm;
}

.thHCon .partcenter span {
    font-weight: 900;
    font-size: small;
    margin-bottom: 15px;
}

.child {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: small;
    font-weight: 700;
    height: 33.33%;
    padding: 2mm;
}

.headerThree .azmounLabel {
    height: 9mm;
    min-height: 9mm;
    max-height: 9mm;
    width: 100%;
    border: 0.8mm solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: x-small;
    font-weight: 600;
    text-align: center;
    margin-top: 2mm;
    margin-bottom: 4mm;
}

.headerThree .azmounLabel span:nth-child(1) {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3mm;
    font-size: x-small;
    font-weight: 400;
}

.headerThree .azmounLabel span:nth-child(2) {
    width: calc(100% - 80px);
    height: 9mm;
    min-height: 9mm;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3mm;
    font-size: x-small;
    font-weight: 400;
    border-left: 0.4mm solid black;
    border-right: 0.4mm solid black;
}

.headerThree .azmounLabel span:nth-child(3) {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3mm;
    font-size: x-small;
    font-weight: 400;
}


/* ------------- */


/* ------------- */


/* ------------- */

.content {
    width: 100%;
    border: 0.4mm solid black;
    border-bottom: none;
}

.quesContain {
    width: 100%;
    border-bottom: 0.4mm solid black;
    border-left: 0.4mm solid black;
    border-right: 0.4mm solid black;
    font-family: "vazir", sans-serif;
    color: black;
    font-size: small;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.quesContain .numoo {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    padding-bottom: 0px;
}

.quesContain .quesoo {
    width: calc(100% - 80px);
    border-left: 0.4mm solid black;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    border-right: 0.4mm solid black;
    border-left: 0.4mm solid black;
}

.quesContain .quesoo div:first-child {
    width: 100% !important;
}

.quesContain .quesoo * {
    font-family: "vazir", sans-serif !important;
    background-color: transparent !important;
    font-size: small !important;
    font-weight: 500 !important;
}

.quesContain .baromoo {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    padding-bottom: 0px;
}

.quesContainStart {
    width: 100%;
    border: 0.4mm solid black;
    font-family: "vazir", sans-serif;
    color: black;
    font-size: small;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.quesContainStart .numoo {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    padding-bottom: 0px;
}

.quesContainStart .quesoo {
    width: calc(100% - 80px);
    border-left: 0.4mm solid black;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    border-right: 0.4mm solid black;
    border-left: 0.4mm solid black;
}

.quesContainStart .quesoo * {
    font-family: "vazir", sans-serif !important;
    background-color: transparent !important;
    font-size: small !important;
    font-weight: 500 !important;
}

.quesContainStart .baromoo {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    padding-bottom: 0px;
}


/* -------- */

.footer {
    width: 100%;
    height: 7mm;
    min-height: 7mm;
    padding: 3px;
    text-align: center;
    border-right: 0.4mm solid black;
    border-bottom: 0.4mm solid black;
    border-left: 0.4mm solid black;
    font-family: "vazir", sans-serif;
    color: black;
    font-size: medium;
    font-weight: 600;
}