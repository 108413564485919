    .selectedCourse {
        width: 100%;
        min-height: auto;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background-color: #333;
        color: white;
        font-family: "vazir";
        font-weight: 600;
        font-size: small;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        padding: 5px;
        padding-top: 15px;
        padding-left: 40px;
    }
    
    .number {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        color: #333;
        position: absolute;
        right: 5px;
        top: 5px;
    }
    
    .delBtn {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        color: #333;
        position: absolute;
        left: 3px;
        top: 3px;
        cursor: pointer;
    }
    
    .darsNameee {
        width: 100%;
        min-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 700;
        text-align: right;
    }
    
    .mabhasName {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 300;
        text-align: right;
    }
    /* .detailsCon {
        width: 100%;
        height: 100%;
        padding: 5px;
        text-align: justify;
        line-height: 0.6cm;
        font-family: "vazir";
        font-weight: 500;
        font-size: x-small;
        color: white;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    
    .conCon::-webkit-scrollbar {
        display: none;
    } */