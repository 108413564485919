Container1 {
    width: 100vw;
    height: 100%;
    background-color: #f8fefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
    max-width: 1440px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.header .right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.header .center {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.pageLabel {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    color: #333;
}

.homeBack {
    width: 50px;
    height: 50px;
    border-radius: 7px 7px 7px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    cursor: pointer;
}

.homeIcon {
    color: white;
    margin-bottom: 4px;
    margin-left: 4px;
    font-size: 27px;
}

.contentContainer {
    width: 100%;
    max-width: 1440px;
    height: 100%;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
}

.orderLabel {
    width: 90%;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nextBtn {
    width: 70px;
    height: 30px;
    border-radius: 15px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
    background-color: #f9a21c;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    margin: 0px 30px;
}

.nextBtnNone {
    display: none;
}

.text {
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: large;
    color: #333;
}

.qnumbersContent {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    color: #333;
    margin: 0px 10px;
}

.qnumbersContent .label {
    font-weight: 800;
    font-size: small;
    color: #333;
    margin-left: 5px;
}

.qnumbersContent .qnum {
    font-weight: 400;
    font-size: small;
    color: #333;
    margin-left: 3px;
}

.gradeSumContent {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    color: #333;
    margin: 0px 10px;
}

.gradeSumContent .label {
    font-weight: 800;
    font-size: small;
    color: #333;
    margin-left: 5px;
}

.gradeSumContent .sum {
    font-weight: 400;
    font-size: small;
    color: #333;
    margin-left: 3px;
}