* {
    box-sizing: border-box !important;
}

Container1 {
    width: 100vw;
    height: 100%;
    background-color: #f8fefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
    max-width: 1440px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.header .right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.header .center {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.pageLabel {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    color: #333;
}

.homeBack {
    width: 50px;
    height: 50px;
    border-radius: 7px 7px 7px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    cursor: pointer;
}

.homeIcon {
    color: white;
    margin-bottom: 4px;
    margin-left: 4px;
    font-size: 27px;
}

.contentContainer {
    width: 100%;
    max-width: 1440px;
    height: 100%;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
}

.orderLabel {
    width: 90%;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nextBtn {
    width: 70px;
    height: 30px;
    border-radius: 15px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
    background-color: #f9a21c;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    margin: 0px 30px;
}

.nextBtnNone {
    display: none;
}

.text {
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: large;
    color: #333;
}

.qnumbersContent {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    color: #333;
    margin: 0px 10px;
}

.qnumbersContent .label {
    font-weight: 800;
    font-size: small;
    color: #333;
    margin-left: 5px;
}

.qnumbersContent .qnum {
    font-weight: 400;
    font-size: small;
    color: #333;
    margin-left: 3px;
}

.gradeSumContent {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    color: #333;
    margin: 0px 10px;
}

.gradeSumContent .label {
    font-weight: 800;
    font-size: small;
    color: #333;
    margin-left: 5px;
}

.gradeSumContent .sum {
    font-weight: 400;
    font-size: small;
    color: #333;
    margin-left: 3px;
}

.sarbarg {
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    background-color: white;
}

.sarbargActive {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    background-color: white;
}


/* over dark bg of form */

.overDarkBak {
    position: fixed;
    z-index: 2000;
    min-width: 100vw;
    min-height: 100%;
    top: 0%;
    left: 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.438);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.formContain {
    width: 320px;
    max-height: 500px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    margin: auto;
    padding: 10px;
    font-family: "vazir", sans-serif;
    font-size: small;
    color: #333;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    position: relative;
}

.closeForm {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: large;
    cursor: pointer;
}


/* Hide scrollbar for Chrome, Safari and Opera */

.formContain::-webkit-scrollbar {
    display: none;
}

.formContain .mainText {
    width: 100%;
    font-weight: 900;
    font-size: medium;
    text-align: center;
    margin-bottom: 20px;
}

.formContain .warningTextOne {
    width: 100%;
    font-weight: 800;
    font-size: medium;
    text-align: center;
    line-height: 0.9cm;
    margin-bottom: 20px;
    color: #c28326;
}

.formContain .warningTextTwo {
    width: 100%;
    font-weight: 700;
    font-size: medium;
    text-align: center;
    line-height: 0.9cm;
    margin-bottom: 20px;
    color: #c28326;
}

.formContain .label {
    width: 100%;
    font-weight: 700;
    margin-bottom: 7px;
    margin-top: 15px;
}

.formContain input {
    width: 100%;
    height: 30px;
    min-height: 30px;
    direction: rtl;
    font-size: small;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border: 0.1mm solid rgb(154, 154, 154);
    border-radius: 7px;
    padding: 0 10px;
}

.formContain .pdfBtn {
    width: 120px;
    height: 35px;
    min-height: 35px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    background-color: #f9a21c;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    margin: 30px auto;
}

.AlertForm {
    color: red;
    font-weight: 900;
    font-size: small;
    margin: 30px auto;
    text-align: center;
}

.formContain .itemCon {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.formContain .itemCon .nextBtn {
    width: 150px;
    font-weight: 900;
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    .header {
        width: 100%;
        max-width: 1440px;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
    }
    .header .right {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .header .center {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }
    /* ----- */
    .orderLabel {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nextBtn {
        width: 70px;
        height: 30px;
        border-radius: 15px;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        cursor: pointer;
        background-color: #f9a21c;
        font-family: "vazir", sans-serif;
        font-weight: 500;
        font-size: small;
        margin: 0px 10px;
    }
    .nextBtnNone {
        display: none;
    }
    .text {
        width: calc(100% - 90px);
        font-family: "vazir", sans-serif;
        font-weight: 700;
        font-size: medium;
        color: #333;
    }
    .qnumbersContent {
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin: 0px 5px;
    }
    .qnumbersContent .label {
        font-weight: 800;
        font-size: xx-small;
        color: #333;
        margin-left: 5px;
        text-align: center;
    }
    .qnumbersContent .qnum {
        font-weight: 400;
        font-size: small;
        color: #333;
        margin-left: 3px;
    }
    .gradeSumContent {
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin: 0px 10px;
    }
    .gradeSumContent .label {
        font-weight: 800;
        font-size: xx-small;
        color: #333;
        margin-left: 5px;
    }
    .scrollWidthCon {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: auto;
        padding: 10px;
    }
}