.Container1 {
    width: 100vw;
    height: 100%;
    background-color: #f8fefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
    max-width: 1440px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.header .right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.header .center {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.pageLabel {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    color: #333;
}

.homeBack {
    width: 50px;
    height: 50px;
    border-radius: 7px 7px 7px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    cursor: pointer;
}

.homeIcon {
    color: white;
    margin-bottom: 4px;
    margin-left: 4px;
    font-size: 27px;
}

.contentContainer {
    width: 100%;
    max-width: 1440px;
    height: 100%;
    min-height: 700px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: transparent;
}

.box1content {
    width: 30%;
    height: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.madeAzmounBtn {
    width: 200px;
    height: 70px;
    background-color: #333333;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px 20px 20px 0px;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: large;
    transition: all 0.3s;
    margin-bottom: 50px;
    cursor: pointer;
}

.buyAccountBtn {
    width: 200px;
    height: 70px;
    background-color: #333333;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px 20px 20px 0px;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: large;
    transition: all 0.3s;
    cursor: pointer;
}

.box2content {
    width: 40%;
    height: 100%;
    padding: 50px;
    padding: 0px 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.box2content img {
    width: 80%;
    margin-bottom: 60px;
}

.Container1 .ContainerOver .Container2 {
    width: 100vw;
    max-width: 1280px;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.Container1 .ContainerOver .Container2 ::-webkit-scrollbar {
    display: none;
}

.headerContain {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.headerContain .right {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.headerContain .left {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.contentContain {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.Page1Item {
    width: 270px;
    height: 270px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 45px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-large;
    color: #333;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: top;
    margin: 15px;
    cursor: pointer;
    transition: all 0.3s;
}

.Page1Item:hover {
    transform: scale(0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}


/* ------------- */

.noPic {
    width: 70px;
    height: 70px;
    border: 0.3mm solid rgb(66, 66, 66);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.profilePic {
    width: 70px;
    height: 70px;
    border: 0.3mm solid rgb(66, 66, 66);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.detailsItem {
    width: 250px;
    height: 40px;
    border-radius: 10px;
    background-color: #f0f2f5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    font-family: "vazir", sans-serif;
    font-size: large;
    color: rgb(74, 74, 74);
    margin-bottom: 10px;
}

.detailsItem .label {
    font-weight: 700;
}

.detailsItem .text {
    font-weight: 400;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.editDetails {
    font-family: "vazir", sans-serif;
    font-size: medium;
    font-weight: 900;
    color: rgb(74, 74, 74);
    cursor: pointer;
    width: 250px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
    text-decoration: underline;
}

.box3content {
    width: 30%;
    height: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 80px;
}

.accountLabel {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    color: rgb(74, 74, 74);
    font-size: large;
    margin-bottom: 10px;
}

.accountItem {
    width: 75px;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    border-radius: 10px;
    margin: 3px;
}

.accountItem .label {
    width: 100%;
    height: 30%;
    text-align: center;
    font-family: "vazir", sans-serif;
    font-size: x-small;
    color: rgb(74, 74, 74);
    display: flex;
    justify-content: center;
    align-items: center;
}

.accountItem .data {
    width: 100%;
    height: 70%;
    text-align: center;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 700;
    color: rgb(74, 74, 74);
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert {
    width: 95%;
    padding: 7px 10px;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin-bottom: 7px;
    color: rgb(74, 74, 74);
    font-family: "vazir", sans-serif;
}

.buyAcountBtnn {
    width: 50%;
    height: 30px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9a21c;
    margin-top: 25px;
    cursor: pointer;
}


/* -------- */

.o1one {
    position: fixed;
    z-index: 20;
    min-width: 100vw;
    min-height: 100%;
    top: 0%;
    left: 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.6);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.o2two {
    width: 320px;
    max-height: 600px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    margin: auto;
    padding: 40px 10px;
    font-family: "vazir", sans-serif;
    font-size: small;
    color: #333;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    position: relative;
}

.oClose {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: large;
    cursor: pointer;
}


/* Hide scrollbar for Chrome, Safari and Opera */

.o2two::-webkit-scrollbar {
    display: none;
}

.o2two .olabel {
    width: 100%;
    font-weight: 700;
    margin-bottom: 7px;
    margin-top: 15px;
}

.o2two input {
    width: 100%;
    height: 30px;
    min-height: 30px;
    direction: rtl;
    font-size: small;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border: 0.1mm solid rgb(154, 154, 154);
    border-radius: 7px;
    padding: 0 10px;
}

.oitem1 {
    width: 100%;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.profImg[type="file"] {
    display: none;
}

.oAddImg {
    margin-right: 20px;
    font-size: large;
    cursor: pointer;
}

.oVirayehBtn {
    width: 70px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-size: large;
    font-weight: 700;
    color: rgb(70, 70, 70);
    background-color: #f9a21c;
    border-radius: 10px;
    cursor: pointer;
    margin: 15px auto;
}

.azmounLabel {
    width: 90%;
    background-color: #f0f2f5;
    color: rgb(74, 74, 74);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "vazir", sans-serif;
    font-size: medium;
    font-weight: 800;
    margin-bottom: 10px;
}

.overDarkBak {
    position: fixed;
    z-index: 2000;
    min-width: 100vw;
    min-height: 100%;
    top: 0%;
    left: 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.438);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.formContain {
    width: 320px;
    max-height: 500px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    margin: auto;
    padding: 10px;
    font-family: "vazir", sans-serif;
    font-size: small;
    color: #333;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    position: relative;
}

.formContain::-webkit-scrollbar {
    display: none;
}

.formContain .mainText {
    width: 100%;
    font-weight: 900;
    font-size: medium;
    text-align: center;
    margin-bottom: 20px;
}

.formContain .itemCon {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.formContain .itemCon .nextBtn {
    width: 150px;
    font-weight: 900;
}

.backToFirstPageCon {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.backToFirstPageBtn {
    width: 200px;
    height: 70px;
    background-color: #333333;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px 20px 20px 0px;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: large;
    transition: all 0.3s;
    margin-bottom: 50px;
    cursor: pointer;
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    .header {
        width: 100%;
        max-width: 1440px;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
    }
    .header .right {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .header .center {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }
    /* ---- */
    .contentContainer {
        width: 100%;
        max-width: 1440px;
        height: 100%;
        min-height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
    }
    .box1content {
        width: 100%;
        height: 100%;
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .madeAzmounBtn {
        width: 200px;
        height: 70px;
        background-color: #333333;
        color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 20px 20px 20px 0px;
        box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
        font-family: "vazir", sans-serif;
        font-weight: 700;
        font-size: large;
        transition: all 0.3s;
        margin-bottom: 50px;
        cursor: pointer;
    }
    .buyAccountBtn {
        width: 200px;
        height: 70px;
        background-color: #333333;
        color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 20px 20px 20px 0px;
        box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
        font-family: "vazir", sans-serif;
        font-weight: 700;
        font-size: large;
        transition: all 0.3s;
        cursor: pointer;
    }
    .box2content {
        display: none;
    }
    .box3content {
        width: 100%;
        height: 100%;
        padding: 0px 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}