.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 300;
    background-color: rgba(51, 51, 51, 0.53);
}

.closeBtn {
    position: absolute;
    top: 3px;
    left: 10px;
    color: #e13e76;
    cursor: pointer;
}

.container2 {
    width: 90vw;
    height: 500px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    position: relative;
    margin-top: 10vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    padding: 20px 20px;
}

.container2 ::-webkit-scrollbar {
    display: none;
}

@-moz-document url-prefix() {
    .container2 {
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
    }
}

.container2 .easyDetails {
    width: 100%;
    height: 330px;
    min-height: 330px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    margin-bottom: 70px;
}

.itemCon {
    width: 100%;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
}

.itemCon .lab {
    font-weight: 700;
    color: #333;
    font-size: medium;
    margin-left: 5px;
}

.itemCon .content {
    font-weight: 400;
    color: #333;
    font-size: medium;
    margin-left: 5px;
}

.questionCon {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.topContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.QuesLabelShoeBtn {
    width: 80px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 7px;
    font-weight: 800;
    font-size: large;
    cursor: pointer;
    font-family: "vazir";
}

.questionLabelCon {
    width: 100%;
    min-height: 40px;
    border-radius: 7px;
    background-color: #e5e5e5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    margin: 3px 6px;
    padding: 15px;
    cursor: pointer;
    margin-bottom: 20px;
}

.parCon {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 7px;
    margin-bottom: 5px;
}

.parCon .label {
    width: 100%;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: medium;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.parCon .label p {
    font-family: "vazir", sans-serif !important;
}

.parCon .label sapn {
    font-family: "vazir", sans-serif !important;
}

.parCon .label * {
    font-family: "vazir", sans-serif !important;
}

.parCon .content {
    width: 100%;
    font-family: "vazir", sans-serif !important;
    font-weight: 400;
    font-size: medium;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: justify !important;
    margin-bottom: 10px;
    padding: 5px 15px;
    border-radius: 7px;
}

.parCon .content p {
    font-family: "vazir", sans-serif !important;
}

.parCon .content sapn {
    font-family: "vazir", sans-serif !important;
}

.parCon .content * {
    font-family: "vazir", sans-serif !important;
}

.printBtn {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7a7a7e;
    color: white;
    font-weight: 900;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 7px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-bottom: 30px;
}