.Container1 {
    width: 100vw;
    height: 100vh;
    background-color: #f8fefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
    max-width: 1440px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.header .right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.header .center {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.pageLabel {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    color: #333;
}

.homeBack {
    width: 50px;
    height: 50px;
    border-radius: 7px 7px 7px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    cursor: pointer;
}

.homeIcon {
    color: white;
    margin-bottom: 4px;
    margin-left: 4px;
    font-size: 27px;
}

.contentContainer {
    width: 100%;
    max-width: 1440px;
    height: 100%;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
}

.mainLabel {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-large;
}

.accountContain {
    width: 90%;
    min-width: 310px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 20px;
}

.accountContain::-webkit-scrollbar {
    display: none;
}


/* ----------------- */

.acountitemCon {
    width: 300px;
    min-width: 300px;
    height: 200px;
    min-height: 200px;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    font-family: "vazir", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.acountitemCon .topAcount {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    padding: 10px;
}

.acountitemCon .topAcount .topRight {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.acountitemCon .topAcount .topRight img {
    width: 70px;
    height: 70px;
    border-radius: 15px;
}

.acountitemCon .topAcount .topLeft {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.acountitemCon .topAcount .topLeft .span1 {
    color: #333;
    font-size: x-large;
    font-weight: 900;
    margin-bottom: 25px;
}

.acountitemCon .topAcount .topLeft .span2 {
    color: rgb(97, 97, 97);
    font-size: large;
    font-weight: 900;
    margin-bottom: 15px;
}

.acountitemCon .topAcount .topLeft .span3 {
    color: rgb(97, 97, 97);
    font-size: large;
    font-weight: 900;
    margin-bottom: 15px;
}

.acountitemCon .bottomAcount {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 20%;
    padding: 10px;
    border-top: 0.2mm solid rgb(97, 97, 97);
}

.acountitemCon .bottomAcount .span4 {
    color: #333;
    font-size: large;
    font-weight: 900;
    position: relative;
}

.offItem {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    right: -120%;
    bottom: 0;
    font-size: small;
}

.offLine {
    width: 100%;
    height: 2px;
    background-color: red;
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(10deg);
}

.offArrow {
    position: absolute;
    right: -20%;
    top: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}