.question {
    width: 90%;
    min-height: 400px;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #cfd8dc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    font-family: "vazir", sans-serif;
}

.question img {
    width: auto;
    max-width: 90%;
    height: auto;
}

.question .statusBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 7px;
    background-color: #80deea;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.question .statusBtn .byt {
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.question .statusBtn .yellow {
    background-color: #ffeb3b;
}

.question .statusBtn .red {
    background-color: #e53935;
}

.question .statusBtn .green {
    background-color: #8bc34a;
}

.question .number {
    width: 100%;
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: x-large;
}

.question .text {
    font-family: "vazir", sans-serif !important;
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: large;
    background-color: #fafafa;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.question .text * {
    text-align: justify;
    width: auto !important;
    max-width: 90% !important;
}

.question .text div {
    width: 97% !important;
}

.question .text span {
    font-family: "vazir", sans-serif !important;
}

.question .text p {
    font-family: "vazir", sans-serif !important;
}

.question .text div {
    width: 100%;
    padding: 7px 20px;
    text-align: justify;
    font-family: "vazir", sans-serif !important;
}

.question .answer {
    width: 95%;
    margin: 10px auto;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    font-weight: 700;
    font-size: large;
    background-color: #fafafa;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
}

.question .answer input {
    margin-left: 7px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 4px;
    cursor: pointer;
}

.question .answer div.g1 {
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 3px;
}

.question .answer div.g1 span {
    font-family: "vazir", sans-serif !important;
    margin-left: 3px;
}

.question .answer div.g2 {
    font-weight: 300;
    text-align: justify;
    padding: 3px;
    width: 75%;
}

.question .answer div.g2 * {
    font-weight: 300;
    text-align: justify;
    padding: 3px;
    width: auto !important;
    max-width: 90% !important;
}

.question .answer div.g2 div {
    width: 97% !important;
}

.question .answer div.g2 span {
    font-family: "vazir", sans-serif !important;
    font-weight: 5300;
    margin-left: 3px;
}

.question .answer div.g2 p {
    font-family: "vazir", sans-serif !important;
}

.question .answer div {
    margin-left: 7px;
}