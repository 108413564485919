.Container1 {
    width: 100vw;
    height: 100%;
    max-height: 100%;
    background-color: #f8fefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
    height: 120px;
    max-width: 1440px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.header .right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.header .center {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.contentContainer {
    width: 100%;
    max-width: 1440px;
    height: calc(100% - 120px);
    min-height: 700px;
    padding-bottom: 70px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
}

.box1content {
    width: 50%;
    height: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selectedDarsBox {
    width: 350px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #f9a21c;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    margin: 15px;
    transition: all 0.3s;
    padding: 30px;
}

.selectedDarsBox .mainLabel {
    width: 100%;
    padding: 15px;
    border-bottom: 0.5mm solid #53370b;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    color: white;
    padding-top: 0;
}

.selectedDarsBox button {
    width: 80%;
    background-color: #333;
    color: white;
    font-weight: 800;
    font-size: medium;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    margin: 20px 0px;
}

.selectedDarsBox .selectedCourse {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.selectedDarsBox .selectedCourse::-webkit-scrollbar {
    display: none;
}

.madeAzmounBtn {
    width: 200px;
    height: 70px;
    background-color: #333333;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px 20px 20px 0px;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: large;
    transition: all 0.3s;
    margin-bottom: 50px;
    cursor: pointer;
}

.buyAccountBtn {
    width: 200px;
    height: 70px;
    background-color: #333333;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: large;
    transition: all 0.3s;
    cursor: pointer;
}

.box2content {
    width: 50%;
    height: 100%;
    padding: 50px;
    padding: 0px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box2content img {
    width: 80%;
    margin-bottom: 60px;
}


/* ---------- */

@media screen and (max-width: 690px) and (min-width: 320px) {
    .Container1 {
        width: 100vw;
        min-height: 100vh;
        height: auto;
        background-color: #f8fefe;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .header {
        width: 100%;
        max-width: 1440px;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
    }
    .header .right {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .header .center {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }
    .contentContainer {
        width: 100%;
        max-width: 1440px;
        height: auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
        background-color: transparent;
        padding-top: 20px;
    }
    .box1content {
        width: 100%;
        height: 50%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .selectedDarsBox {
        width: 310px;
        min-height: 300px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: #f9a21c;
        border-radius: 16px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        font-family: "vazir", sans-serif;
        font-weight: 600;
        font-size: small;
        line-height: 0.8cm;
        text-align: center;
        color: #333;
        margin: 15px;
        transition: all 0.3s;
        padding: 30px;
    }
    .selectedDarsBox .mainLabel {
        width: 100%;
        padding: 10px;
        border-bottom: 0.5mm solid #53370b;
        font-family: "vazir", sans-serif;
        font-weight: 900;
        font-size: medium;
        color: white;
        padding-top: 0;
    }
    .selectedDarsBox button {
        width: 80%;
        background-color: #333;
        color: white;
        font-weight: 800;
        font-size: small;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
        margin: 20px 0px;
    }
    .selectedDarsBox .selectedCourse {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .selectedDarsBox .selectedCourse::-webkit-scrollbar {
        display: none;
    }
    .madeAzmounBtn {
        width: 200px;
        height: 70px;
        background-color: #333333;
        color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 20px 20px 20px 0px;
        box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
        font-family: "vazir", sans-serif;
        font-weight: 700;
        font-size: large;
        transition: all 0.3s;
        margin-bottom: 50px;
        cursor: pointer;
    }
    .buyAccountBtn {
        width: 200px;
        height: 50px;
        min-height: 50px;
        background-color: #333333;
        color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 20px;
        box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
        font-family: "vazir", sans-serif;
        font-weight: 700;
        font-size: large;
        transition: all 0.3s;
        cursor: pointer;
    }
    .box2content {
        width: 100%;
        height: 250px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    .box2content img {
        width: 70%;
        margin-bottom: 20px;
    }
}