.backDark {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(51, 51, 51, 0.438);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.payehCon {
    width: 25vw;
    max-width: 150px;
    min-width: 80px;
    height: 40vh;
    max-height: 600px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    margin-top: 25vh;
    padding: 25px 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-left: 15px;
    position: relative;
}

.payehCon::-webkit-scrollbar {
    display: none;
}

.payehLab {
    width: 100%;
    height: 35px;
    border-radius: 16px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #f9a21c;
    color: white;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payehItem {
    width: 90%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    color: #333;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    border-radius: 15px;
    cursor: pointer;
}

.selectItem {
    border: 1px solid #f9a21c;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.booksCon {
    width: 30vw;
    max-width: 190px;
    min-width: 100px;
    height: 40vh;
    max-height: 600px;
    min-height: 400px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    margin-top: 25vh;
    margin-left: 15px;
    position: relative;
}

.conCon {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 25px 0px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.conCon::-webkit-scrollbar {
    display: none;
}

.booksCon .labelBook {
    width: 100%;
    height: 35px;
    border-radius: 16px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background-color: #f9a21c;
    color: white;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booksCon .search {
    width: 100%;
    height: 35px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booksCon .search input {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    direction: rtl;
    padding: 0px 10px;
}

.bookItem {
    width: 90%;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    color: #333;
    font-family: "vazir", sans-serif;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 400;
    font-size: small;
    overflow: hidden;
    padding: 3px;
    text-align: center;
}

.addToPishAzmoon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background-color: white;
    border: 1mm solid #f9a21c;
    color: #f9a21c;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: calc(50vh - 37px);
}

.mabhasCon {
    width: 30vw;
    max-width: 190px;
    min-width: 100px;
    height: 40vh;
    max-height: 600px;
    min-height: 400px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    margin-top: 25vh;
    margin-left: 15px;
    position: relative;
}

.mabhasCon .labelmabhas {
    width: 100%;
    height: 35px;
    border-radius: 16px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #f9a21c;
    color: white;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mabhasCon .search {
    width: 100%;
    height: 35px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mabhasCon .search input {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    direction: rtl;
    padding: 0px 10px;
}

.mabhasItem {
    width: 90%;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    color: #333;
    font-family: "vazir", sans-serif;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 400;
    font-size: small;
    overflow: hidden;
    padding: 3px;
    text-align: center;
}

.numberCon {
    width: 30vw;
    max-width: 100px;
    min-width: 60px;
    height: 40vh;
    max-height: 600px;
    min-height: 400px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    margin-top: 25vh;
    margin-left: 15px;
    position: relative;
}

.numberCon .labelnumber {
    width: 100%;
    height: 35px;
    border-radius: 16px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #f9a21c;
    color: white;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.numberItem {
    width: 90%;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    color: #333;
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: small;
    border-radius: 15px;
    cursor: pointer;
    overflow: hidden;
}