.containerDrop {
    width: 100%;
}

.items {
    width: 100%;
    border: 1px solid grey;
    margin: 10px;
    background-color: lightblue;
    padding: 10px;
}

.quessContainer {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
    border-radius: 10px;
    margin-bottom: 15px;
    position: relative;
    font-family: "vazir";
    background-color: #edf4f9;
    cursor: pointer;
}

.quessContainerActive {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
    border-radius: 10px;
    margin-bottom: 15px;
    position: relative;
    font-family: "vazir";
    cursor: pointer;
    background-color: #edf4f9;
}

.quessContainer p {
    font-family: "vazir", sans-serif !important;
}

.quessContainer sapn {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

.quessContainer * {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

.quesText {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "vazir";
    margin-bottom: 15px;
}

.quesText .labelM {
    color: #333;
    font-weight: 800;
    font-size: medium;
    margin-bottom: 4px;
}

.answerText {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "vazir";
}

.answerText .labelM {
    color: #333;
    font-weight: 800;
    font-size: medium;
    margin-bottom: 4px;
}

.quessDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.quessDetails .detailsContain {
    padding: 3px 7px;
    background-color: #333 !important;
    border-radius: 5px;
    margin: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: xx-small;
}

.quesText>div p {
    font-family: "vazir", sans-serif !important;
}

.quesText>div sapn {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

.quesText>div * {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

.answerText>div p {
    font-family: "vazir", sans-serif !important;
}

.answerText>div sapn {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

.answerText>div * {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    /* ----- */
    .quessContainer {
        width: 100%;
        height: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
        border-radius: 10px;
        margin-bottom: 15px;
        position: relative;
        font-family: "vazir";
        background-color: #edf4f9;
        cursor: pointer;
    }
    .quessContainerActive {
        width: 100%;
        height: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
        border-radius: 10px;
        margin-bottom: 15px;
        position: relative;
        font-family: "vazir";
        cursor: pointer;
        background-color: #edf4f9 !important;
    }
    .quessContainer p {
        font-family: "vazir", sans-serif !important;
        font-size: small !important;
    }
    .quessContainer sapn {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quessContainer * {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quessContainerActive p {
        font-family: "vazir", sans-serif !important;
        font-size: small !important;
    }
    .quessContainerActive sapn {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quessContainerActive * {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quesText {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: "vazir";
        margin-bottom: 15px;
    }
    .quesText .labelM {
        color: #333;
        font-weight: 800;
        font-size: small;
        margin-bottom: 4px;
    }
    .answerText {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: "vazir";
    }
    .answerText .labelM {
        color: #333;
        font-weight: 800;
        font-size: small;
        margin-bottom: 4px;
    }
    .quessDetails {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    .quessDetails .detailsContain {
        padding: 2px 3px;
        background-color: #333 !important;
        border-radius: 5px;
        margin: 5px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        font-weight: 600;
        font-size: xx-small !important;
    }
    /* --------- */
    /* --------- */
}