.mainContainer {
    width: 100vw;
    height: 100%;
    background-color: #f8fefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
    max-width: 1440px;
    padding: 10px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pageLabel {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    color: #333;
}

.homeBack {
    width: 50px;
    height: 50px;
    border-radius: 7px 7px 7px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    cursor: pointer;
}

.homeIcon {
    color: white;
    margin-bottom: 4px;
    margin-left: 4px;
    font-size: 27px;
}

.contentContainer {
    width: 100%;
    max-width: 1440px;
    height: 100%;
    min-height: 700px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.registerSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imgOne {
    width: 300px;
    height: 370px;
}

.registerBtn {
    width: 200px;
    height: 50px;
    background-color: #333333;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px 20px 20px 0px;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: 40px;
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    .header {
        width: 100%;
        max-width: 1440px;
        padding: 10px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    .imgOne {
        display: none;
    }
    .contentContainer {
        width: 100%;
        max-width: 1440px;
        height: 100%;
        min-height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .registerBtn {
        width: 200px;
        height: 50px;
        background-color: #333333;
        color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 20px 20px 20px 0px;
        box-shadow: rgb(0 0 0 / 30%) 0px 30px 40px -7px;
        font-family: "vazir", sans-serif;
        font-weight: 900;
        font-size: large;
        transition: all 0.3s;
        cursor: pointer;
        margin-top: 20px;
    }
}