.Container1 {
    width: 100vw;
    height: 100%;
    background-color: #f8fefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
    max-width: 1440px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.header .right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.header .center {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.contentContainer {
    width: 100%;
    max-width: 1440px;
    min-height: 700px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: transparent;
    padding: 5px 10px;
}

.rightCon {
    width: 65%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 15px;
    padding: 5px;
}

.rightCon .rightHeader {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "vazir", sans-serif;
}

.rightCon .rightHeader .darsContent {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.rightCon .rightHeader .darsContent .label {
    font-weight: 800;
    font-size: small;
    color: #333;
    margin-left: 5px;
}

.rightCon .rightHeader .darsContent .darsName {
    font-weight: 400;
    font-size: small;
    color: #333;
    margin-left: 3px;
}

.rightCon .rightHeader .mabahesContent {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.rightCon .rightHeader .mabahesContent .label {
    font-weight: 800;
    font-size: small;
    color: #333;
    margin-left: 5px;
}

.rightCon .rightHeader .mabahesContent .mabhasName {
    font-weight: 400;
    font-size: small;
    color: #333;
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: 0.3mm solid black;
    padding-left: 3px;
}

.rightCon .rightContent {
    width: 100%;
    min-height: 200px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    overflow: auto;
    /* Firefox */
    position: relative;
}

.rightContent::-webkit-scrollbar {
    display: none;
}


/* ---------- */

.leftCon {
    width: 30%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 15px;
    padding: 5px;
    position: relative;
}

.leftCon .leftHeader {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "vazir", sans-serif;
}

.leftCon .leftHeader .qnumbersContent {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.leftCon .leftHeader .qnumbersContent .label {
    font-weight: 800;
    font-size: small;
    color: #333;
    margin-left: 5px;
}

.leftCon .leftHeader .qnumbersContent .qnum {
    font-weight: 400;
    font-size: small;
    color: #333;
    margin-left: 3px;
}

.leftCon .leftHeader .gradeSumContent {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.leftCon .leftHeader .gradeSumContent .label {
    font-weight: 800;
    font-size: small;
    color: #333;
    margin-left: 5px;
}

.leftCon .leftHeader .gradeSumContent .sum {
    font-weight: 400;
    font-size: small;
    color: #333;
    margin-left: 3px;
}

.leftCon .leftContent {
    width: 100%;
    min-height: 200px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    overflow: auto;
    /* Firefox */
    position: relative;
}

.leftCon .leftContent::-webkit-scrollbar {
    display: none;
}

.quessContainer2 {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
    border-radius: 10px;
    margin-bottom: 15px;
    position: relative;
    font-family: "vazir";
    background-color: #edf4f9;
    cursor: pointer;
}

.deleteSelect {
    position: absolute;
    top: -7px;
    left: -7px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
    background-color: #edf4f9 !important;
}

.deleteSelect .delBtn {
    font-size: xx-small;
}

.quessContainer2 .quesText p {
    font-family: "vazir", sans-serif !important;
    font-size: small !important;
}

.quessContainer2 .quesText sapn {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
    font-size: small !important;
}

.quessContainer2 .quesText * {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
    font-size: small !important;
}

.quessContainer2 .answerText p {
    font-family: "vazir", sans-serif !important;
    font-size: small !important;
}

.quessContainer2 .answerText sapn {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
    font-size: small !important;
}

.quessContainer2 .answerText * {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
    font-size: small !important;
}

.quesText .labelM2 {
    color: #333;
    font-weight: 800;
    font-size: small;
    margin-bottom: 4px;
}

.answerText .labelM2 {
    color: #333;
    font-weight: 800;
    font-size: small;
    margin-bottom: 4px;
}

.quessDetails .detailsContain {
    padding: 3px 7px;
    background-color: #333 !important;
    border-radius: 5px;
    margin: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: xx-small;
}

.quessDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.quessContainer2 .quessDetails .detailsContain {
    padding: 3px 7px;
    background-color: #333 !important;
    border-radius: 5px;
    margin: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: xx-small !important;
}


/* ---------------- */

.quessContainer {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
    border-radius: 10px;
    margin-bottom: 15px;
    position: relative;
    font-family: "vazir";
    background-color: #edf4f9;
    cursor: pointer;
}

.quessContainerActive {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
    border-radius: 10px;
    margin-bottom: 15px;
    position: relative;
    font-family: "vazir";
    cursor: pointer;
    background-color: #f9a21c !important;
}

.quessContainer p {
    font-family: "vazir", sans-serif !important;
}

.quessContainer sapn {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

.quessContainer * {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

.quessContainerActive p {
    font-family: "vazir", sans-serif !important;
}

.quessContainerActive sapn {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

.quessContainerActive * {
    font-family: "vazir", sans-serif !important;
    background: transparent !important;
}

.quesText {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "vazir";
    margin-bottom: 15px;
}

.quesText .labelM {
    color: #333;
    font-weight: 800;
    font-size: medium;
    margin-bottom: 4px;
}

.answerText {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "vazir";
}

.answerText .labelM {
    color: #333;
    font-weight: 800;
    font-size: medium;
    margin-bottom: 4px;
}

.quessDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.quessDetails .detailsContain {
    padding: 3px 7px;
    background-color: #333 !important;
    border-radius: 5px;
    margin: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: xx-small;
}

.filterContainer {
    width: 100%;
    padding: 15px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    overflow: auto;
    /* Firefox */
}

.filterContainer::-webkit-scrollbar {
    display: none;
}

.filterContainer .filterItemActive {
    padding: 5px 8px;
    font-family: "vazir", sans-serif;
    font-size: small;
    color: #333;
    font-weight: 700;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 3px 5px;
    background-color: #f9a21c;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
    white-space: nowrap;
}

.filterContainer .filterItemDeActive {
    padding: 5px 7px;
    font-family: "vazir", sans-serif;
    font-size: small;
    color: #333;
    font-weight: 700;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 3px 5px;
    background-color: #edf4f9;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
    white-space: nowrap;
}

.nextBtn {
    position: absolute;
    top: 25px;
    left: 15px;
    width: 70px;
    height: 30px;
    border-radius: 15px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
    background-color: #f9a21c;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
}

.nextBtnNone {
    display: none;
}

.selectQuesInMobileBtn {
    display: none;
}

.backToFirstPageCon {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    .header {
        width: 100%;
        max-width: 1440px;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
    }
    .header .right {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .header .center {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }
    /* ----- */
    .leftCon {
        width: 30%;
        min-height: 100%;
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
        border-radius: 15px;
        padding: 5px;
        position: relative;
    }
    /* ----- */
    .rightCon {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
        border-radius: 15px;
        padding: 5px;
    }
    .rightCon .rightHeader .mabahesContent {
        display: none;
    }
    .filterContainer {
        width: 100%;
        padding: 5px 5px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        overflow: auto;
        /* Firefox */
    }
    /* ----- */
    .quessContainer {
        width: 100%;
        height: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
        border-radius: 10px;
        margin-bottom: 15px;
        position: relative;
        font-family: "vazir";
        background-color: #edf4f9;
        cursor: pointer;
    }
    .quessContainerActive {
        width: 100%;
        height: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
        border-radius: 10px;
        margin-bottom: 15px;
        position: relative;
        font-family: "vazir";
        cursor: pointer;
        background-color: #f9a21c !important;
    }
    .quessContainer p {
        font-family: "vazir", sans-serif !important;
        font-size: small !important;
    }
    .quessContainer sapn {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quessContainer * {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quessContainerActive p {
        font-family: "vazir", sans-serif !important;
        font-size: small !important;
    }
    .quessContainerActive sapn {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quessContainerActive * {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quesText {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: "vazir";
        margin-bottom: 15px;
    }
    .quesText .labelM {
        color: #333;
        font-weight: 800;
        font-size: small;
        margin-bottom: 4px;
    }
    .answerText {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: "vazir";
    }
    .answerText .labelM {
        color: #333;
        font-weight: 800;
        font-size: small;
        margin-bottom: 4px;
    }
    .quessDetails {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    .quessDetails .detailsContain {
        padding: 2px 3px;
        background-color: #333 !important;
        border-radius: 5px;
        margin: 5px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        font-weight: 600;
        font-size: xx-small !important;
    }
    /* --------- */
    /* --------- */
    .selectQuesInMobileBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 4px;
        right: 10px;
        width: 45px;
        height: 45px;
        z-index: 20;
        background-color: #f9a21c;
        box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
        border-radius: 50%;
        font-family: "vazir", sans-serif;
        font-weight: 400;
        font-size: xx-small;
        color: #333;
        text-align: center;
        padding: 2px;
    }
    .selectQuesInMobileCon {
        min-width: 100vw;
        max-width: 100%;
        min-height: 100vh;
        max-height: 100%;
        position: fixed;
        z-index: 30;
        top: 0%;
        left: 0%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: rgba(51, 51, 51, 0.438);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 10px 0px;
    }
    .topFix {
        width: 100%;
        height: 70px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 5px;
        font-family: "vazir";
        color: white;
    }
    .bottomScroll {
        width: 100%;
        height: calc(100% - 70px);
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        overflow: auto;
        /* Firefox */
        padding: 10px;
        scroll-padding-top: 15px;
        scroll-margin-top: 10px;
        scroll-snap-type: y proximity;
    }
    .bottomScroll::-webkit-scrollbar {
        display: none;
    }
    .closeMobileCon {
        position: absolute;
        top: 9px;
        right: 9px;
        cursor: pointer;
    }
    /* --- */
    .quessContainer2 {
        width: 100%;
        height: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 3px, rgb(0 0 0 / 23%) 0px 1px 3px;
        border-radius: 10px;
        margin-bottom: 15px;
        position: relative;
        font-family: "vazir";
        background-color: #edf4f9;
        cursor: pointer;
        scroll-snap-align: start;
    }
    .deleteSelect {
        position: absolute;
        top: -7px;
        left: -7px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        cursor: pointer;
        background-color: #edf4f9 !important;
    }
    .deleteSelect .delBtn {
        font-size: xx-small;
    }
    .quessContainer2 .quesText p {
        font-family: "vazir", sans-serif !important;
        font-size: small !important;
    }
    .quessContainer2 .quesText sapn {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quessContainer2 .quesText * {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quessContainer2 .answerText p {
        font-family: "vazir", sans-serif !important;
        font-size: small !important;
    }
    .quessContainer2 .answerText sapn {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quessContainer2 .answerText * {
        font-family: "vazir", sans-serif !important;
        background: transparent !important;
        font-size: small !important;
    }
    .quesText .labelM2 {
        color: #333;
        font-weight: 800;
        font-size: small;
        margin-bottom: 4px;
    }
    .answerText .labelM2 {
        color: #333;
        font-weight: 800;
        font-size: small;
        margin-bottom: 4px;
    }
    .quessDetails .detailsContain {
        padding: 3px 7px;
        background-color: #333 !important;
        border-radius: 5px;
        margin: 5px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        font-weight: 400;
        font-size: xx-small;
    }
    .quessDetails {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    .quessContainer2 .quessDetails .detailsContain {
        padding: 3px 7px;
        background-color: #333 !important;
        border-radius: 5px;
        margin: 5px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        font-weight: 600;
        font-size: xx-small !important;
    }
}