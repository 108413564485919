.dailyTextContainer {
    width: 90%;
    height: 80px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 20px 20px 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    color: #333;
    text-align: center;
    line-height: 0.8cm;
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    .dailyTextContainer {
        width: 90%;
        height: 40px;
        border-radius: 16px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 20px 20px 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: large;
        color: #333;
        text-align: center;
        line-height: 0.8cm;
    }
}