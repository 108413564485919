.userDetailsContainer {
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    margin-left: 20px;
}

.userDetailsContainer .userIconCon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #f9a21c;
    color: #333;
    font-size: 25px;
    cursor: pointer;
}

.profilePic {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.overBackground {
    position: fixed;
    z-index: 19;
    min-width: 100vw;
    min-height: 100%;
    top: 0%;
    left: 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.438);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.DetailsPan {
    position: absolute;
    z-index: 20;
    background-color: #f8fefe;
    top: 110%;
    min-height: 500px;
    min-width: 300px;
    width: 300px;
    height: 400px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px;
}

.DetailsPan .detailsItem {
    width: 95%;
    padding: 7px 10px;
    background-color: #333;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 7px;
}

.DetailsPan .detailsItem .label {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    color: white;
}

.DetailsPan .detailsItem .data {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: medium;
    color: white;
}

.accountDetailsCon {
    width: 90%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.accountItem {
    width: 75px;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 7px;
    border: 2px solid #023e8a;
    margin: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.accountItem .label {
    width: 100%;
    height: 30%;
    text-align: center;
    font-family: "vazir", sans-serif;
    font-size: x-small;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accountItem .data {
    width: 100%;
    height: 70%;
    text-align: center;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 700;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exitBtn {
    width: 50%;
    height: 30px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e43f5a;
    margin-top: 25px;
    cursor: pointer;
}

.alert {
    width: 95%;
    padding: 7px 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 7px;
    color: #333;
    font-family: "vazir", sans-serif;
}

.buyAcountBtnn {
    width: 50%;
    height: 30px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9a21c;
    margin-top: 25px;
    cursor: pointer;
}

.showPanelLink {
    width: 90%;
    height: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: medium;
    color: #f9a21c;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
}

.showPanelLink:hover {
    color: #b07a2a;
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    .userDetailsContainer {
        width: 50px;
        min-width: 50px;
        height: 50px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        margin-left: 20px;
    }
    .userDetailsContainer .userIconCon {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #f9a21c;
        color: #333;
        font-size: 25px;
        cursor: pointer;
    }
    .profilePic {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .DetailsPan {
        position: fixed;
        z-index: 20;
        background-color: #f8fefe;
        top: 70px;
        right: 10px;
        min-height: 500px;
        min-width: 300px;
        width: 300px;
        height: 400px;
        border-radius: 16px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 0px;
    }
    .DetailsPan .detailsItem {
        width: 95%;
        padding: 7px 10px;
        background-color: #333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        margin-bottom: 7px;
    }
    .DetailsPan .detailsItem .label {
        font-family: "vazir", sans-serif;
        font-weight: 600;
        font-size: medium;
        color: white;
    }
    .DetailsPan .detailsItem .data {
        font-family: "vazir", sans-serif;
        font-weight: 400;
        font-size: medium;
        color: white;
    }
    .accountDetailsCon {
        width: 90%;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    }
    .accountItem {
        width: 75px;
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 7px;
        border: 2px solid #023e8a;
        margin: 3px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
    .accountItem .label {
        width: 100%;
        height: 30%;
        text-align: center;
        font-family: "vazir", sans-serif;
        font-size: x-small;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .accountItem .data {
        width: 100%;
        height: 70%;
        text-align: center;
        font-family: "vazir", sans-serif;
        font-size: small;
        font-weight: 700;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .exitBtn {
        width: 50%;
        height: 30px;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        font-family: "vazir", sans-serif;
        font-size: small;
        font-weight: 700;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e43f5a;
        margin-top: 25px;
        cursor: pointer;
    }
    .alert {
        width: 95%;
        padding: 7px 10px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        margin-bottom: 7px;
        color: #333;
        font-family: "vazir", sans-serif;
    }
    .buyAcountBtnn {
        width: 50%;
        height: 30px;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        font-family: "vazir", sans-serif;
        font-size: small;
        font-weight: 700;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9a21c;
        margin-top: 25px;
        cursor: pointer;
    }
    .showPanelLink {
        width: 90%;
        height: 20px;
        font-family: "vazir", sans-serif;
        font-weight: 500;
        font-size: medium;
        color: #f9a21c;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
    }
    .showPanelLink:hover {
        color: #b07a2a;
    }
}