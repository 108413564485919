.CurentTimeContainer {
    width: 150px;
    height: 80px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    margin-left: 20px;
}

.CurentTimeContainer .showTimeCon {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50%;
}

.CurentTimeContainer .showTimeCon .dayOfWeek {
    background-color: #f9a21c;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
    font-size: small;
    margin-left: 5px;
}

.CurentTimeContainer .showTimeCon .timeNow {
    background-color: #333333;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
    font-size: small;
}

.CurentTimeContainer .showDataCon {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50%;
}

.CurentTimeContainer .showDataCon>div {
    padding: 5px;
    margin-left: 3px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-size: small;
    font-weight: 500;
    background-color: white;
    color: #333333;
}

@media screen and (max-width: 690px) and (min-width: 320px) {
    .CurentTimeContainer {
        width: 110px;
        height: 50px;
        border-radius: 16px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        margin-left: 20px;
    }
    .CurentTimeContainer .showTimeCon .dayOfWeek {
        background-color: #f9a21c;
        padding: 3px 5px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        color: white;
        font-size: x-small;
        margin-left: 5px;
    }
    .CurentTimeContainer .showTimeCon .timeNow {
        background-color: #333333;
        padding: 3px 5px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        color: white;
        font-size: x-small;
    }
    .CurentTimeContainer .showDataCon>div {
        padding: 3px;
        margin-left: 3px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        font-size: x-small;
        font-weight: 500;
        background-color: white;
        color: #333333;
    }
}